import React from "react";
import {useHistory} from 'react-router-dom';
import { useActionState } from '../../../lib/ActionState'
import { FileSubmitter } from '../../FileSubmitter'
import { ToastContainer, toast } from 'react-toastify';
import { Loader } from '@teespring/ts-components';

export function TradeItemStockUpdater({ updateTradeItemsWithCSV }) {
    const [updatingTradeItems, performTradeItemsUpdate, resetTradeItemsUpdate] = useActionState(updateTradeItemsWithCSV);
    const history = useHistory();

    const renderUpdatedTradeItems = (tradeItems) =>
    (<>
        <h2>Updated Trade Items</h2>
        <table className="table">
            <thead>
                <tr>
                    <th>Trade Item ID</th>
                    <th>In Stock</th>
                </tr>
            </thead>
            <tbody>
                {tradeItems.map(tradeItem =>
                    <tr key={tradeItem.id}>
                        <td>{tradeItem.id}</td>
                        <td>{tradeItem.inStock.toString()}</td>
                    </tr>)
                }
            </tbody>
        </table>

        <button className="btn"
            onClick={resetTradeItemsUpdate}>
            Upload a new CSV
        </button>
    </>)

    const onCancel = () => history.goBack();

    const submitTradeItemsUpdate = (file) => performTradeItemsUpdate(file).catch(toast.error);

    return (<>
        <div className="main__title">Update Trade Items Stock</div>
        { (updatingTradeItems.isNotStartedYet() || updatingTradeItems.isFailure()) &&
            <FileSubmitter onFileSubmit={submitTradeItemsUpdate} onCancel={onCancel} />}
        { updatingTradeItems.isPending() && <Loader />}
        { updatingTradeItems.withSuccessfulResult(renderUpdatedTradeItems)}
        <ToastContainer />
    </>)
}
