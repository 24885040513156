import teespring, { extractErrorMessage } from '../../../axios/teespring';

export function updateTradeItemsWithCSV(tradeItemsCSV) {
    const body = new FormData();
    body.append('csv_file', tradeItemsCSV);

    return teespring.post(`update_trade_items_stock`, body)
                    .then(({data}) => data)
                    .catch(extractErrorMessage);
}

export const actions = { updateTradeItemsWithCSV };

export default actions;

