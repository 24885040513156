import React, {useCallback} from 'react';
import {BulkShippingRateProcessTable} from "./BulkShippingRateProcessTable";
import { useParams } from "react-router-dom";
import {useLoader} from "../../../hooks/Hooks";

export function BulkShippingRateProcess({getJobs}) {
    const { id } = useParams();
    const getJobsByID = useCallback(() => getJobs(id), [getJobs, id]);
    const [withLoader, jobs] = useLoader(getJobsByID);

    return (
        withLoader(<BulkShippingRateProcessTable jobs={jobs}/>)
    )
}
