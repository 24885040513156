import React from "react";
import {NavBar} from "../NavBar/NavBar";

import './MainLayout.scss';

const userData = (admin) => ({
    id: admin.id,
    name: admin.email,
    email: admin.email,
    navLinks: [
        {
            label: 'Back to Teespring Admin',
            locationUrl: process.env.REACT_APP_TEESPRING_BACKEND_URL,
            locationPath: '',
            dashboard: 'v2'
        },
    ]
});

const MainLayout = ({admin, children}) => {
    return (
        <div className="main__layout">
            <NavBar user={userData(admin)}/>
            <div className="main__content">
                {children}
            </div>
        </div>
    )
};

export default MainLayout;
