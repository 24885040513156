import React from "react";
import './Table.scss'
import {Link} from 'react-router-dom';
import {useLoader} from "../hooks/Hooks";

export function ProcessTable({getProcesses}) {
    const [withLoader, processes] = useLoader(getProcesses);

    return (
        withLoader(<Processes processes={processes}/>)
    )
}

export function Processes({processes}) {
    function BulkShippingRateProcess({process}) {
        return (
            <tr>
                <Link to={location => `${location.pathname}/${process.id}`}>{process.id}</Link>
                <td>{process.adminUserEmail}</td>
                <td>{process.createdAt}</td>
            </tr>
        )
    }

    return (
        <table className="table">
            <thead>
            <tr>
                <th>ID</th>
                <th>Admin User Email</th>
                <th>Created at</th>
            </tr>
            </thead>
            <tbody>
            {processes.map(process =>
                <BulkShippingRateProcess
                    key={process.id}
                    process={process}
                />)}
            </tbody>
        </table>
    )
}
