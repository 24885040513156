import React from "react";

export function BulkShippingRateProcessTable({jobs}) {

    function BulkShippingRateProcessJob({job}) {
        return (
            <tr>
                <td>{job.shippingRateId}</td>
                <td>{job.baseCostInCents}</td>
                <td>{job.status}</td>
                <td>{job.errorMessage}</td>
            </tr>
        )
    }

    return (
        <div>
            <div className="main__title">Bulk Update Shipping Rate Process Jobs</div>
            <table className="table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Base cost in Cents</th>
                    <th>Status</th>
                    <th>Error Message</th>
                </tr>
                </thead>
                <tbody>
                {jobs.map(job => <BulkShippingRateProcessJob key={job.id} job={job}/>)}
                </tbody>
            </table>
        </div>
    )
}
