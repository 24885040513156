export const PRODUCT_MANAGEMENT = [
    {
        icon: 'Layers',
        label: 'Product Management',
        locationPath: '/',
        dashboard: 'v2',
        subNav: [
            {
                label: 'Update Trade Items Stock',
                locationUrl: '/update-trade-items-stock',
                locationPath: '/',
                dashboard: 'v2',
                subNav: []
            },
        ]
    }
];
