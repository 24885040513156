import React from 'react';
import TierItem from './TierItem';
import CreateTierItem from './CreateTierItem';
import {useLoader} from "../../../hooks/Hooks";

export function TierPage({getTiers, deleteTier, updateTier, createTier}) {
    const [withLoader, tiers, setTiers] = useLoader(getTiers);

    const replaceTier = (updatedTier) => setTiers(tiers.map(tier => (tier.id === updatedTier.id) ? updatedTier : tier));
    const removeTier = (removedTier) => setTiers(tiers.filter(({id}) => id !== removedTier.id));
    const addTier = (newTier) => setTiers(tiers.concat([newTier]));

    const tierActions = {
        createTier: (tier) => createTier(tier).then((createdTier) => addTier(createdTier)),
        updateTier: (tier) => updateTier(tier).then((updatedTier) => replaceTier(updatedTier)),
        deleteTier: (tier) => deleteTier(tier).then(() => removeTier(tier)),
    };

    return (withLoader(<Tiers tiers={tiers} tierActions={tierActions}/>))
}

export function Tiers({tiers, tierActions}) {
    const {createTier, updateTier, deleteTier} = tierActions;
    return (
        <div>
            <h1> Top Account Tiers </h1>
            <ul data-testid="tiers">
                {tiers.map(tier =>
                    <TierItem
                        key={tier.id}
                        tier={tier}
                        deleteTier={deleteTier}
                        updateTier={updateTier}
                    />)}
            </ul>
            <CreateTierItem createTier={createTier}/>
        </div>
    );
}
