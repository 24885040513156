import React, { Component } from "react";
import { TrackJS } from "trackjs";

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(hasError) {
        return { hasError: true };
    }

    componentDidCatch(hasError, errorInfo) {
        if (errorInfo && errorInfo.componentStack) {
            console.log(errorInfo.componentStack);
        }

        TrackJS.track(hasError);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}