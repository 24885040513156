import React from "react";
import {useHistory} from 'react-router-dom';
import {FileSubmitter} from "../../FileSubmitter";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function BulkUploader({uploadNewFile}) {
    const history = useHistory();

    const onFileSubmit = (file) => {
        uploadNewFile(file)
            .then((response) => {
                history.push(`bulk-shipping-rates-processes/${response.id}`)
            }).catch((error) => {
            toast.error(error);
        });
    }

    const onCancel = () => history.goBack();

    return (
        <div>
            <div className="main__title">Bulk Update Shipping Rates</div>

            <FileSubmitter onFileSubmit={ onFileSubmit } onCancel={onCancel} />
            <ToastContainer/>
        </div>
    )
}
