import { useState } from 'react';

export class ActionState {
  isFailure() {
    return false;
  };
  isNotStartedYet() {
    return false;
  };
  isSuccess() {
    return false;
  };
  isPending() {
    return false;
  }
  withSuccessfulResult(aFunction) { };
};

export class NotStartedYet extends ActionState {
  isNotStartedYet() {
    return true;
  }
};

export class Pending extends ActionState {
  isPending() {
    return true;
  }
};

export class Failure extends ActionState {
  constructor(errorMessage) {
    super();
    this.error = errorMessage;
  }

  isFailure() {
    return true;
  }
};

export class Success extends ActionState {
  constructor(result) {
    super();
    this.result = result;
  };
  isSuccess() {
    return true;
  }
  withSuccessfulResult(aFunction) {
    return aFunction(this.result);
  };
};

export const useActionState = (action) => {
  const [actionState, setActionState] = useState(new NotStartedYet());

  const perform = (...params) => {
    setActionState(new Pending());
    return action(...params)
      .then(value => {
        setActionState(new Success(value));
        return value;
      })
      .catch(error => {
        setActionState(new Failure(error));
        throw error;
      })
  }

  const reset = () => setActionState(new NotStartedYet());

  return [actionState, perform, reset];
};
