import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { TrackJS } from 'trackjs';
import { ErrorBoundary } from "./ErrorBoundary";
import teespring from './axios/teespring';

const setupTrackJS = (admin) => {
    if (process.env.NODE_ENV === 'production') {
        TrackJS.install({
            token: "e0729fb7330843a1b736f34189024885",
            application: "admin",
            userId: admin.email,
            version: process.env.REACT_APP_COMMIT_REF,
        });
    }
};

const setupAdminUI = (admin) => {
    ReactDOM.render(
        <React.StrictMode>
            <ErrorBoundary>
                <App admin={admin}/>
            </ErrorBoundary>
        </React.StrictMode>,
        document.getElementById('root')
    );
};

const setupAntiCSRFToken = (authenticityToken) => {
    teespring.defaults.headers.common['X-CSRF-Token'] = authenticityToken;
}

const redirectToLogin = (loginUrl) => {
    window.location.replace(loginUrl);
    window.location.href = loginUrl;
};

teespring.get('bootstrap')
    .then(({data}) => {
        setupTrackJS(data.admin);
        setupAdminUI(data.admin);
        setupAntiCSRFToken(data.authenticity_token);
    })
    .catch(({response}) => {
        if (response.status === 401) redirectToLogin(response.data.login_url);
    });


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
