import React from "react";
import {ProcessTable} from "../../ProcessTable";
import {useHistory} from 'react-router-dom';

export function BulkShippingRateProcesses({getProcesses}) {

    const history = useHistory();

    function goToUploadPage() {
        history.push(`bulk-shipping-rates-uploader`)
    }

    return (
        <div>
            <div className="main__title">Bulk Update Shipping Rates</div>

            <div className="centered">
                <button className="btn" onClick={goToUploadPage}>
                    Upload a bulk shipping rate update
                </button>
            </div>
            <ProcessTable getProcesses={getProcesses}/>
        </div>
    )
}
