import React from 'react';

import '@teespring/ts-components/dist/styles/base-styles.css';
import '@teespring/ts-components/dist/styles/ts-styles.css';
import './App.scss';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import {TierPage} from './components/Fulfillment/tiers/Tiers';
import {getTiers, deleteTier, updateTier, createTier} from './components/Fulfillment/tiers/TierActions';

import MainLayout from "./components/MainLayout";

import {BulkUploader} from "./components/Fulfillment/bulkUploader/BulkUploader";
import {BulkShippingRateProcesses} from "./components/Fulfillment/bulkUploader/BulkShippingRateProcesses";
import {BulkShippingRateProcess} from "./components/Fulfillment/bulkUploader/BulkShippingRateProcess";
import {TradeItemStockUpdater} from "./components/ProductManagement/TradeItemStockUpdater/TradeItemStockUpdater";
import {
    getJobs,
    getProcesses,
    uploadCSV
} from "./components/Fulfillment/bulkUploader/BulkShippingRatesProcessesActions";
import { updateTradeItemsWithCSV } from "./components/ProductManagement/TradeItemStockUpdater/TradeItemStockUpdaterActions";

function App({admin}) {
    return (
        <div className="app">
            <Router>
                <Route exact path='/'
                       component={() => (
                           <MainLayout admin={admin} />
                       )}>
                </Route>

                <MainLayout admin={admin}>

                    <Route path='/tiers'
                           component={() => (
                               <TierPage getTiers={getTiers}
                                         deleteTier={deleteTier}
                                         updateTier={updateTier}
                                         createTier={createTier}/>
                           )}/>

                    <Route path='/bulk-shipping-rates-uploader'
                           component={() => (<BulkUploader uploadNewFile={uploadCSV}/>)}>
                    </Route>

                    <Route exact path='/bulk-shipping-rates-processes'
                           render={() => {
                               return (
                                   <BulkShippingRateProcesses getProcesses={getProcesses}/>
                               )
                           }}>
                    </Route>

                    <Route exact path='/bulk-shipping-rates-processes/:id'
                           render={() =>
                               <BulkShippingRateProcess getJobs={getJobs}/>
                           }/>

                    <Route exact path='/update-trade-items-stock'
                           render={() =>
                               <TradeItemStockUpdater updateTradeItemsWithCSV={updateTradeItemsWithCSV} />
                           }/>
                </MainLayout>

            </Router>
        </div>
    );
}

export default App;
