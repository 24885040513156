import teespring, { extractErrorMessage } from "../../../axios/teespring";

export function getJobs(id) {
    return teespring.get(`bulk_shipping_rate_processes/${id}`)
        .then(({data}) => {
            return data.map(job => ({
                id: job.id,
                shippingRateId: job.shippingRateId,
                baseCostInCents: job.baseCostInCents,
                status: job.status,
                errorMessage: job.errorMessage
            }));
        });
}

export function getProcesses() {
    return teespring.get(`bulk_shipping_rate_processes`).then(({data}) => {
        return data.map(process => ({
            id: process.id,
            adminUserEmail: process.adminUserEmail,
            createdAt: process.createdAt
        }));
    });
}

export function uploadCSV(fileSelected) {
    const body = new FormData();
    body.append('csv_file', fileSelected);
    return teespring.post(`bulk_shipping_rate_processes`, body)
        .then(({data}) => data)
        .catch(extractErrorMessage)
}
