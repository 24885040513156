import React, {useState} from "react";
import { FileUploader } from './FileUploader'
import './FileUploader.scss'

export function FileSubmitter({onFileSubmit, onCancel}) {
    const [selectedFile, setSelectedFile] = useState(null);

    return (
        <>
            <FileUploader onFileSelected={setSelectedFile}/>
            <div className="button-align">
                <button className="cancel_button" onClick={onCancel}>Cancel</button>
                <button className="btn"
                        data-testid="file-submit-button"
                        disabled={!selectedFile}
                        onClick={() => onFileSubmit(selectedFile)}>
                    Submit
                </button>
            </div>
        </>
    )
}
