import React from "react";
import { SideNav } from '@teespring/ts-components';
import { NAVIGATION } from "./navigation";

export function NavBar({user}) {
    return <div className="main__navbar">
        <SideNav
            items={NAVIGATION}
            user={user}
            showCollapseControls={false}
            collapsed={false}/>
    </div>;
}