import axios from 'axios';

export default axios.create({
    baseURL: `${process.env.REACT_APP_TEESPRING_BACKEND_URL}/api`,
    withCredentials: true,
});

export const extractErrorMessage = (({response}) => {
    const errorMessage = (response.data && response.data.error) || "Something went wrong"
    throw errorMessage
})