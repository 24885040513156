import React, {useRef, useState} from "react";
import {Icon} from '@teespring/ts-components';
import './FileUploader.scss'

export function FileUploader({onFileSelected}) {
    const inputRef = useRef(null);
    const [file, setFile] = useState(null);

    function handleOnClick() {
        inputRef.current.click();
    }

    function handleOnChange(event) {
        const fileToUpload = event.target.files[0];
        setFile(fileToUpload);
        onFileSelected(fileToUpload)
    }

    return (
        <div className="file_uploader"
             onClick={handleOnClick}
             onChange={handleOnChange}>
            <Icon name="UploadCloud" className="upload_icon" size={26}/>
            <span>Browse</span>
            <div data-testid="file-name">{file && file.name}</div>
            <input data-testid='file-uploader-id' type="file" ref={inputRef} style={{display: "none"}}/>
        </div>
    )
}
