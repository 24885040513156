import React, {useState} from "react";
import { LoaderButton } from '@teespring/ts-components';
import { useActionState } from '../../../lib/ActionState'
import { Icon, ModalConductor } from '@teespring/ts-components';

function TierItem({tier, updateTier, deleteTier}) {
    const [activeModal, setActiveModal] = useState({ id: null, mProps: {}});
    const [newName, setNewName] = useState(tier.name);
    const [updating, performUpdate, resetUpdatingState] = useActionState(() => updateTier({...tier, name: newName}));
    const [deleting, performDelete, resetDeletingState] = useActionState(() => deleteTier({...tier}));

    const nameWasModified = newName !== tier.name;

    function updateTierName(event) {
        setNewName(event.target.value);
        resetUpdatingState();
        resetDeletingState();
    }

    function onUpdate(event) {
        event.preventDefault();

        if(nameWasModified) performUpdate().catch(x => x);
    }

    function onDelete(event) {
        event.preventDefault();

        performDelete().catch(x => closeDeleteTierModal());
    }

    function askForDeleteConfirmation(event) {
        event.preventDefault();

        setActiveModal({id: `delete-tier-modal-${tier.id}`, mProps: {}});
    }

    function closeDeleteTierModal() {
        setActiveModal({id: null});
    }

    function DeleteTierModal() {
        return (<ModalConductor
                    activeModal={ activeModal }
                    setActiveModal={ setActiveModal }
                    modals={ [
                    { id: `delete-tier-modal-${tier.id}`,
                    node: <div data-testid="delete-tier-confirmation-modal">
                        <h2>{`Are you sure you want to delete the tier ${tier.name}?`}</h2>
                            <div className="DeleteTierModalButtons">
                                <LoaderButton text='Delete'
                                              onClick={onDelete}
                                              loadingText='Deleting'
                                              loading={deleting.isPending()}/>
                                <button className="btn btn--transparent" onClick={() => setActiveModal({id: null})}>Cancel</button>
                            </div>
                        </div> }
                    ] }
                />)
    }

    return (
        <div data-testid="tier-item">
            <DeleteTierModal />
            <li>
            <form onSubmit={onUpdate} className="TierItem">
                <input type="text"
                       name="name"
                       value={newName}
                       data-testid="tier-item-name-input"
                       disabled={updating.isPending()}
                       onChange={updateTierName}
                       required/>
                <div className="TierItemActions">
                    <button type='submit' data-testid="edit-tier-button" disabled={!nameWasModified || updating.isPending()}>
                        {updating.isSuccess() && <Icon name='Check'/>}
                        {updating.isPending() && <Icon className='rotate' name='Loader' />}
                        {(updating.isNotStartedYet() || updating.isFailure()) && <Icon name='Save'/>}
                    </button>
                    <button type='button' onClick={askForDeleteConfirmation} data-testid="delete-tier-button">
                        <Icon name='Trash2'/>
                    </button>
                    {updating.isFailure() && <span><div>There was an error trying to update the tier</div><strong>{updating.error}</strong></span>}
                    {deleting.isFailure() && <span><div>There was an error trying to delete the tier</div><strong>{deleting.error}</strong></span>}
                </div>
            </form>

        </li>
        </div>
        );
}


export default TierItem;
