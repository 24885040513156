export const FULFILLMENT = [
    {
        icon: 'Layers',
        label: 'Fulfillment',
        locationPath: '/',
        dashboard: 'v2',
        subNav: [
            {
                label: 'Bulk Shipping Rates Processes',
                locationUrl: '/bulk-shipping-rates-processes',
                locationPath: '/',
                dashboard: 'v2',
                subNav: []
            },
            {
                label: 'Bulk Shipping Rates Uploader',
                locationUrl: '/bulk-shipping-rates-uploader',
                locationPath: '/',
                dashboard: 'v2',
                subNav: []
            },
            {
                label: 'Tiers',
                locationUrl: '/tiers',
                locationPath: '/',
                dashboard: 'v2',
                subNav: []
            },
        ]
    }
];
