import teespring, { extractErrorMessage } from '../../../axios/teespring';

export const getTiers = () => teespring.get('tiers').then(({data}) => data.map(tier => ({id: tier.id, name: tier.name})))

export const deleteTier = (tier) => teespring.delete(`tiers/${tier.id}`, tier).catch(extractErrorMessage);;

export const updateTier = (tier) => teespring.put(`tiers/${tier.id}`, tier).then(({data}) => data).catch(extractErrorMessage);;

export const createTier = (tier) => teespring.post(`tiers`, tier).then(({data}) => data).catch(extractErrorMessage);

export const actions = { getTiers, deleteTier, updateTier, createTier };

export default actions;
