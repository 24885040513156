import React, {useState} from "react";
import { useActionState } from '../../../lib/ActionState'
import { Icon } from '@teespring/ts-components';

function CreateTierItem({createTier}) {
    const [name, setName] = useState("");
    const [creating, performCreate, resetCreateState] = useActionState(() => createTier({name}));

    const nameIsEmpty = name === '';

    function onCreate(event) {
        event.preventDefault();

        performCreate().then(() => setName("")).catch(x => x);
    }

    function updateName(event) {
        resetCreateState();

        setName(event.target.value);
    }

    return (
        <div>
            <form onSubmit={onCreate} className="TierItem">
                <input type="text"
                    name="new-tier-name"
                    placeholder="Name for new tier..."
                    data-testid="new-tier-name"
                    value={name}
                    onChange={updateName}
                    disabled={ creating.isPending() }
                    required
                    />
                <div className="TierItemActions">
                    <button
                        name="create-tier"
                        data-testid="create-tier-button"
                        disabled={ creating.isPending() || nameIsEmpty }
                        type="submit">
                        { creating.isPending() ? <Icon className='rotate' name='Loader' /> : <Icon name='PlusSquare' />}
                    </button>
                    { creating.isFailure() && <span><div>There was an error trying to create the tier</div><strong>{creating.error}</strong></span> }
                </div>
            </form>
        </div>
    );
}

export default CreateTierItem;
