import React, {useEffect, useState} from "react";
import {Loader} from '@teespring/ts-components';

export function useLoader(getAsyncResource) {
    const [loading, setLoading] = useState(true);
    const [asyncResource, setAsyncResource] = useState(null);

    useEffect(() => {
        getAsyncResource().then((resources) => {
            setAsyncResource(resources);
            setLoading(false)
        })
    }, [getAsyncResource]);

    function withLoader(Component) {
        return (
            loading ? <Loader/> : Component
        )
    }

    return [withLoader, asyncResource, setAsyncResource]
}
